




























import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import AbstractField from '@/classes/Form/Fields/AbstractField'
import Field from '@/classes/Form/Field'

@Component({
  components: {
    FormField: () => import('@/components/Form/FormField.vue'),
  },
})
export default class ArrayField extends AbstractField {
  addItem(): void {
    if (_.get(this.form.data, this.fullKey)) {
      _.set(this.form.data, this.fullKey, [..._.get(this.form.data, this.fullKey), {}])
    } else {
      _.set(this.form.data, this.fullKey, [{}])
    }

    this.removeError()
  }

  removeItem(index: number): void {
    _.set(
      this.form.data,
      this.fullKey,
      _.get(this.form.data, this.fullKey).filter((value: any, i: number) => i !== index),
    )
  }

  getChild(child: Field): Field {
    if (this.field.dense) {
      child.setDense(true)
    }

    return child
  }
}
